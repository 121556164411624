.plans-container{
    margin-top: -4rem;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    position: relative;
}
.plans-blur1{
    width: 32rem;
    height: 23rem;
    left: 0rem;
}
.plans-blur2{
    width: 32rem;
    height: 23rem;
    top: 10rem;
    right: 0rem;
}
@media screen and (max-width: 768px){
    .programs-header{
        flex-direction: column;
        gap: 1rem;
        font-size: x-large;
        align-items: center;
        justify-content: center;
        margin: 2rem;
    }
    .program-categories{
        flex-direction: column;
    }
    
}


.plans {
    display: flex;
    align-items: center;
    justify-content: center;
    gap : 3rem;
}
.plan{
    display: flex;
    flex-direction: column;
    background: var(--caloryCard);
    color: white;
    gap: 2rem;
    padding: 1.5rem;
    width: 15rem;
}
.plan:nth-child(2)
{
    background: var(--planCard);
    transform: scale(1.1);
}
.plan>svg{
    fill: var(--orange);
    width: 2rem;
    height: 2rem;
}
.plan>:nth-child(2)
{
    font-size: 1rem;
    font-weight: bold;
}
.plan>:nth-child(3)
{
    font-size: 3rem;
    font-weight: bold;
}
.plan>:nth-child(5)
{
    font-size: 0.8rem;
    
}
.features{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.feature{
    display: flex;
    flex-direction: center;
    gap: 1rem;
}
.feature>img{
    width: 1rem;
}
.plans>:nth-child(2)>svg{
    fill: white;
}
.plans>:nth-child(2)>button{
    color: orange;
}
@media screen and (max-width: 768px) {
    .plans{
        flex-direction: column;
    }
    .plans>:nth-child(2)
    {
        transform: none;
    }
}
